<template>
  <div class="cost">
    <Header
      back="返回"
      title="知识产权"
      index="首页"
      titleOne="科创模块"
      titleTwo="知识产权"
      titleThree="软著列表"
      beforeTitle="新增/编辑"
    />
    <div class="content">
      <h1>软著信息</h1>
      <el-form
        :rules="formRule"
        ref="formRule"
        :model="form"
        :label-position="labelPosition"
        label-width="80px"
      >
        <el-form-item label="软著名称" prop="soft_name">
          <el-input v-model="form.soft_name" placeholder="请输入软著名称"></el-input>
        </el-form-item>
        <el-form-item label="企业名称" prop="company_name">
          <el-autocomplete
            class="inline-input"
            v-model="form.company_name"
            :fetch-suggestions="querySearchAsync"
            @select="handleSelect"
            placeholder="请输入企业名称关键字筛选"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="软著简称">
          <el-input v-model="form.short_name" placeholder="请输入软著简称"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="form.status" placeholder="请选择状态">
            <el-option label="已授权" :value="1"></el-option>
            <el-option label="未受理" :value="2"></el-option>
            <el-option label="受理中" :value="3"></el-option>
            <el-option label="补正" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="登记号" prop="register_number">
          <el-input v-model="form.register_number" placeholder="请输入登记号"></el-input>
        </el-form-item>
        <el-form-item label="版本号" prop="ver">
          <el-input v-model="form.ver" placeholder="请输入版本号"></el-input>
        </el-form-item>
        <el-form-item label="开发完成日期">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            v-model="form.publish_time"
            value-format="yyyy-MM-dd"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="授权下证日期">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            v-model="form.approval_time"
            value-format="yyyy-MM-dd"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="著作权人">
          <el-input v-model="form.copyright" placeholder="请输入著作权人"></el-input>
        </el-form-item>
        <el-form-item label="获得方式" prop="get_type">
          <el-select v-model="form.get_type" placeholder="请选择获得方式">
            <el-option label="自主研发" :value="1">自主研发</el-option>
            <el-option label="转让" :value="2">转让</el-option>
            <el-option label="授赠" :value="3">授赠</el-option>
            <el-option label="并购" :value="4">并购</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="绑定RD">
          <el-autocomplete
            class="inline-input"
            v-model="form.rd_name"
            :fetch-suggestions="fuzzySearch"
            @select="fuzzySelect"
            @input="rdInput"
            placeholder="RD关键字筛选"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="绑定PS">
          <el-autocomplete
            class="inline-input"
            v-model="form.ps_name"
            :fetch-suggestions="fuzzySearched"
            @select="fuzzySelected"
            @input="psInput"
            placeholder="PS关键字筛选"
          ></el-autocomplete>
        </el-form-item>
        <div class="clear"></div>
        <el-form-item label="摘要" style="width: 100%">
          <el-input
            type="textarea"
            v-model="form.desc"
            placeholder="请输入"
            :autosize="{ minRows: 5, maxRows: 5 }"
          ></el-input>
        </el-form-item>

        <div class="clear"></div>
        <div class="ImportTop">
          <el-upload
            class="upload-demo"
            v-model="form.file_name"
            ref="imgupload"
            action="string"
            :http-request="httpRequest"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :file-list="fileList"
          >
            <el-button type="primary" class="ChooseFile">请选择文件上传</el-button>
          </el-upload>
          <span>（上传文件格式支持pdf，jpg，word，压缩包zip/rar）</span>
          <el-link
            v-model="form.url"
            v-if="this.form.url"
            :href="userSite + this.form.url"
            target="_blank"
          >查看附件</el-link>
        </div>
      </el-form>
      <div class="foot_TJ">
        <el-button type="primary" :loading="btnLoading" @click="onSubmit" :disabled="isDisable">确定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import site from "../../components/global.vue";
export default {
  data () {
    return {
      btnLoading: false,//提交按钮动画
      isDisable: false,
      userSite: site.userSite,
      labelPosition: "top",
      form: {
        soft_name: "",
        company_name: "",
        short_name: "",
        status: "",
        register_number: "",
        ver: "",
        publish_time: "",
        approval_time: "",
        copyright: "",
        get_type: "",
        rd_name: "",
        file_name: "",
        url: "",
        file_id: "",
        id: "",
        com_id: "",
        ps_name: "",
        ps_id: "",
        rd_id: '',
      },
      file_name: "",
      url: "",
      file_id: "",
      fileList: [],
      formRule: {
        soft_name: [
          {
            required: true,
            message: "请输入软著名称",
            trigger: "blur",
          },
        ],
        company_name: [
          {
            required: true,
            message: "请输入企业名称关键字",
            trigger: "blur",
          },
        ],
        status: [
          {
            required: true,
            message: "请选择状态",
            trigger: "change",
          },
        ],
        // register_number: [
        //   {
        //     required: true,
        //     message: "请输入登记号",
        //     trigger: "blur",
        //   },
        // ],
        // ver: [
        //   {
        //     required: true,
        //     message: "请输入版本号",
        //     trigger: "blur",
        //   },
        // ],
        get_type: [
          {
            required: true,
            message: "请选择获得方式",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    meber_id () {
      return this.$route.query.id;
    },
  },
  mounted () {
    if (this.meber_id) {
      this.axios
        .get("/api/soft/view", {
          params: {
            id: this.meber_id,
          },
        })
        .then((res) => {
          this.form = res.data;
          this.fileList = res.data.file;
        });
    }
  },
  methods: {
    rdInput () {
      if (this.form.rd_name == '') {
        this.form.rd_id = ''
      }
    },
    psInput () {
      if (this.form.ps_name == '') {
        this.form.ps_id = ''
      }
    },
    httpRequest (param) {
      console.log(param);
      let file = param.file;
      console.log({ file });
      const form = new FormData();
      form.append("file", file);
      form.append("model", "soft");
      form.append("model_id", this.meber_id);
      this.upload("/api/file/upload_file", form).then((res) => {
        this.form.file_name = res.data.name;
        this.form.url = res.data.url;
        this.form.file_id = res.data.id;
      });
    },
    upload (url, params) {
      return axios
        .post(url, params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          return new Promise((resolve, reject) => {
            resolve(res);
          });
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
    // ps接口
    fuzzySearched (queryString, cb) {
      clearTimeout(this.timeout);
      var results = [];
      if (queryString == "") {
        cb(results);
      } else {
        this.axios
          .get("/api/ps/select_ps_list", {
            params: {
              keywords: queryString,
            },
          })
          .then((res) => {
            // console.log(res);
            if (res.message == "查询成功") {
              for (let i = 0; i < res.data.length; i++) {
                const element = res.data[i];
                // console.log(element);
                results.push({
                  value: element.ps_name,
                  id: element.id,
                });
                // console.log(results);
              }
              cb(results);
            } else {
              results = [];
              cb(results);
            }
          });
      }
    },

    fuzzySelected (item) {
      this.form.ps_name = item.value;
      this.form.ps_id = item.id;
    },
    // 上传文件前的过滤
    beforeAvatarUpload (file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt20M;
    },
    querySearchAsync (queryString, cb) {
      clearTimeout(this.timeout);
      var results = [];
      if (queryString == "") {
        cb(results);
      } else {
        this.axios
          .get("/api/company/select_company", {
            params: {
              keywords: queryString,
            },
          })
          .then((res) => {
            if (res.message == "查询成功") {
              for (let i = 0; i < res.data.length; i++) {
                const element = res.data[i];
                console.log(element);
                results.push({
                  value: element.company_name,
                  id: element.id,
                });
                console.log(results);
              }
              cb(results);
            } else {
              results = [];
              cb(results);
            }
          });
      }
    },
    //点击出现搜索后点击的每一项
    handleSelect (item) {
      // console.log(item);
      this.form.com_id = item.id;
      this.form.company_name = item.value;
    },
    fuzzySearch (queryString, cb) {
      clearTimeout(this.timeout);
      var results = [];
      if (queryString == "") {
        cb(results);
      } else {
        this.axios
          .get("/api/rd/select_rd_list", {
            params: {
              keywords: queryString,
            },
          })
          .then((res) => {
            console.log(res);
            if (res.message == "查询成功") {
              for (let i = 0; i < res.data.length; i++) {
                const element = res.data[i];
                console.log(element);
                results.push({
                  value: element.rd_name,
                  id: element.id,
                });
                console.log(results);
              }
              cb(results);
            } else {
              results = [];
              cb(results);
            }
          });
      }
    },
    //点击出现搜索后点击的每一项
    fuzzySelect (item) {
      this.form.rd_name = item.value;
      this.form.rd_id = item.id;
    },
    onSubmit () {
      this.$refs.formRule.validate((vaild) => {
        if (vaild) {
          this.btnLoading = true
          this.axios
            .post("/api/soft/store", this.form)
            .then((res) => {
              console.log(res);
              this.$router.go(-1);
              this.$message({
                type: "success",
                message: "保存成功!",
              });
              this.isDisable = true;
            })
            .catch((res) => {
              console.log("err:", res);
              this.btnLoading = false
            });
        } else {
          this.$message.error("请输入必填项");
          this.btnLoading = false
        }
      });
    },
  },
};
</script>

<style scoped="scoped">
.upload-demo {
  display: inline-block;
}

.ChooseFile {
  width: 224px;
  height: 40px;
  background: #edeef4 !important;
  color: #909399 !important;
  border: none;
}

.UploadFile {
  width: 88px;
  height: 40px;
  background: #0f38ff !important;
  color: #fff !important;
  border: none;
  margin-left: 20px;
}

.el-upload-list {
  display: inline-block;
}

.el-upload-list__item-name {
  margin-top: 20px;
}

::v-deep.el-form-item {
  width: 23%;
  display: inline-block;
  margin-right: 2%;
}
.el-select {
  width: 100%;
}
.ImportTop span {
  color: #a6abc7;
}
</style>
